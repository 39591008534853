import React, { useCallback } from 'react';

const useOnItemChecked = ({ selectedIds, setSelectedIds, allIds }: { selectedIds: string[]; setSelectedIds: (value: React.SetStateAction<string[]>) => void; allIds: string[]; }) => {
  const getSelectedFeedIndexMargins = useCallback(() => {
    const selectedFeedIdsSortedByIndex = selectedIds.sort((a, b) => allIds.indexOf(a) - allIds.indexOf(b));
    const firstSelectedIndex = allIds.findIndex((key) => key === selectedFeedIdsSortedByIndex[0]);
    const lastSelectedIndex = allIds.findIndex((key) => key === selectedFeedIdsSortedByIndex[selectedFeedIdsSortedByIndex.length - 1]);
    return { firstSelectedIndex, lastSelectedIndex };
  }, [selectedIds, allIds]);

  const handleShiftClick = useCallback((clickedIndex: number) => {
    const { firstSelectedIndex, lastSelectedIndex } = getSelectedFeedIndexMargins();

    if (clickedIndex > firstSelectedIndex) {
      setSelectedIds(allIds.slice(firstSelectedIndex, clickedIndex + 1));
    } else {
      setSelectedIds(allIds.slice(clickedIndex, lastSelectedIndex + 1));
    }
  }, [allIds, setSelectedIds, getSelectedFeedIndexMargins]);

  return useCallback(({ isChecked, id, isShiftKey, index }: {isChecked: boolean; id: string; isShiftKey: boolean; index: number;}) => {
    if (isShiftKey) {
      handleShiftClick(index);
    } else if (isChecked) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => prev.filter((_id) => _id !== id));
    }
  }, [handleShiftClick, setSelectedIds]);
};

export default useOnItemChecked;
