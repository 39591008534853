import React from 'react';
import { IoSearchOutline } from 'react-icons/io5';

import isComposing from '../../../shared/foreground/utils/isComposing';
import styles from './SearchInput.module.css';

export default function SearchInput({ className = '', placeholder = 'Find...', autoFocus = false, setQuery, ...rest }: {className?: string; autoFocus?: boolean; placeholder?: string; setQuery: (query: string) => void;}) {
  return (
    <div className={`${styles.searchWrapper} ${className}`}>
      <IoSearchOutline size={20} color="#000" className={styles.searchIcon} />
      <input
        aria-labelledby="search-label"
        autoFocus={autoFocus}
        autoComplete="off"
        type="search"
        className={styles.searchInput}
        placeholder={placeholder}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (isComposing(event)) {
            return;
          }
          if (event.key === 'Enter' || event.keyCode === 40) {
            (event.target as HTMLInputElement).blur();
          }
        }}
        onChange={(event) => {
          setQuery(event.target.value);
        }}
        {...rest}
      />
    </div>
  );
}
