import {
  type SortOrder,
  type TableSortKey,
} from '../../../types';
import {
  updateState,
} from '../../models';

export const setSortFeedsByKey = async (sortKey: TableSortKey): Promise<void> => {
  await updateState(
    (state) => {
      state.client.sortFeedsByKey = sortKey;
    },
    {
      userInteraction: 'unknown',
      eventName: 'feed-sources-sort-key-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
    },
  );
};

export const setSortFeedsByOrder = async (sortOrder: SortOrder): Promise<void> => {
  await updateState(
    (state) => {
      state.client.sortFeedsByOrder = sortOrder;
    },
    {
      userInteraction: 'unknown',
      eventName: 'feed-sources-sort-order-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
    },
  );
};

export const setSortTagsByKey = async (sortKey: TableSortKey): Promise<void> => {
  await updateState(
    (state) => {
      state.client.sortTagsByKey = sortKey;
    },
    {
      userInteraction: 'unknown',
      eventName: 'tags-list-sort-key-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
    },
  );
};

export const setSortTagsByOrder = async (sortOrder: SortOrder): Promise<void> => {
  await updateState(
    (state) => {
      state.client.sortTagsByOrder = sortOrder;
    },
    {
      userInteraction: 'unknown',
      eventName: 'tags-list-sort-order-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
    },
  );
};

export const setSortViewsByKey = async (sortKey: TableSortKey): Promise<void> => {
  await updateState(
    (state) => {
      state.client.sortViewsByKey = sortKey;
    },
    {
      userInteraction: 'unknown',
      eventName: 'view-list-sort-key-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
    },
  );
};

export const setSortViewsByOrder = async (sortOrder: SortOrder): Promise<void> => {
  await updateState(
    (state) => {
      state.client.sortViewsByOrder = sortOrder;
    },
    {
      userInteraction: 'unknown',
      eventName: 'views-list-sort-order-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
    },
  );
};
