import React, { useMemo } from 'react';

import Button from './Button';
import DeleteIcon from './icons/DeleteIcon';
import EditIcon from './icons/EditIcon';
import styles from './LastUpdatedOrActionButtons.module.css';
import Tooltip from './Tooltip';

const LastUpdatedOrActionButtons = React.memo(function LastUpdatedOrActionButtons({ lastUpdated, isFocused, areSelectedItems, children }: {lastUpdated?: number | string; isFocused: boolean;areSelectedItems: boolean; children: React.ReactNode; }) {
  const showActionButtons = useMemo(() => isFocused && !areSelectedItems, [isFocused, areSelectedItems]);

  if (showActionButtons) {
    return (
      <div className={styles.actionsContainer}>
        {children}
      </div>
    );
  }

  return (
    <span>
      {lastUpdated || '-'}
    </span>
  );
});

export const EditButton = React.memo(function EditButton({ onClick }: { onClick: () => void; }) {
  return (
    <Tooltip content="Edit">
      <Button
        className={styles.actionButton}
        tabIndex={-1}
        onClick={onClick}
      >
        <EditIcon />
      </Button>
    </Tooltip>
  );
});

export const DeleteButton = React.memo(function DeleteButton({ onClick, shortcut }: { onClick: () => void; shortcut: string | string[]; }) {
  return (
    <Tooltip content="Delete" shortcut={shortcut}>
      <Button
        className={styles.actionButton}
        tabIndex={-1}
        onClick={onClick}
      >
        <DeleteIcon />
      </Button>
    </Tooltip>
  );
});

export default LastUpdatedOrActionButtons;
