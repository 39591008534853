import React from 'react';

import Icon from './Icon';

export default function StrokeTrashIcon({ className = '', text = 'Trash' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33333 2.66665C5.33333 1.93027 5.93029 1.33331 6.66667 1.33331H9.33333C10.0697 1.33331 10.6667 1.93027 10.6667 2.66665V3.99998H12.6598C12.6639 3.99994 12.668 3.99994 12.6722 3.99998H13.3333C13.7015 3.99998 14 4.29846 14 4.66665C14 5.03484 13.7015 5.33331 13.3333 5.33331H13.2874L12.7534 12.8091C12.6787 13.8558 11.8078 14.6666 10.7585 14.6666H5.24149C4.19221 14.6666 3.32133 13.8558 3.24657 12.8091L2.71259 5.33331H2.66667C2.29848 5.33331 2 5.03484 2 4.66665C2 4.29846 2.29848 3.99998 2.66667 3.99998H3.32783C3.33197 3.99994 3.33609 3.99994 3.34021 3.99998H5.33333V2.66665ZM4.04932 5.33331L4.57652 12.7141C4.60144 13.063 4.89173 13.3333 5.24149 13.3333H10.7585C11.1083 13.3333 11.3986 13.063 11.4235 12.7141L11.9507 5.33331H4.04932ZM9.33333 3.99998V2.66665H6.66667V3.99998H9.33333ZM6.66667 6.66665C7.03486 6.66665 7.33333 6.96512 7.33333 7.33331V11.3333C7.33333 11.7015 7.03486 12 6.66667 12C6.29848 12 6 11.7015 6 11.3333V7.33331C6 6.96512 6.29848 6.66665 6.66667 6.66665ZM9.33333 6.66665C9.70152 6.66665 10 6.96512 10 7.33331V11.3333C10 11.7015 9.70152 12 9.33333 12C8.96514 12 8.66667 11.7015 8.66667 11.3333V7.33331C8.66667 6.96512 8.96514 6.66665 9.33333 6.66665Z" fill="currentColor" />
</svg>
  </Icon>;
}
